import logo from './logo.svg';
import img from './mrg.jpeg'
import './App.css';

function App() {
  return (
   
      <img  src={img}/>
   
  );
}

export default App;
